import { Link } from "gatsby";
import * as _ from "lodash";
import * as React from "react";
import Helmet from "react-helmet";
import ReactTooltip from "react-tooltip";
import "../blog/case-study.scss";
import "../blog/shared.scss";
import HubspotForm from "../components/hubspot-form";
import AmazeePage from "../components/layout";
import AmazeeIoMetadata from "../components/metadata";
import { PageHeaderStandard } from "../components/page-header/page-header";
import { convert_image_link } from "../components/reusable/types";
import { PageTitle } from "../components/title/title";
import {
  ResourceInteruption,
  ResourceInteruptionStatic,
} from "../templates/resources/customers";
import Linked from "../utils/linkedItem";
import RichTextRender from "../utils/rich-text";
import usePreviewData from "../utils/usePreviewData";
import { BlogPostListingAuthor, getBlogPostImage } from "./blog-post-listing";
import "./person.scss";

const moment = require("moment");

const CaseStudyHeaderPost: React.FC<{ post: any }> = ({ post }) => {
  return (
    <header className="blog__post__header d-flex">
      <div className="row">
        <div className="col-12">
          <h1 className="blog__post__header__title">{post.title}</h1>
        </div>
        {/* <div className="col-12 blog__post__metadata post-entry">
                    <BlogPostListingAuthor readLength={post.read_time} date={post.posted_on} image={post.author_image} name={post.author_name} />
                </div> */}
      </div>
    </header>
  );
};

const SocialMediaLinks: React.FC<{ post: any; location: Location }> = ({
  post,
  location,
}) => {
  const urlEncoded = encodeURIComponent(location.href);
  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${urlEncoded}`;
  const twitterShare = `https://twitter.com/home?status=${urlEncoded}`;
  const linkedInShare = `https://www.linkedin.com/shareArticle?mini=true&url=${urlEncoded}&title=&summary=&source=`;

  return (
    <div className="social-media-icons d-flex">
      <div
        className="cursor-pointer facebook social-icon mr-4"
        onClick={() =>
          window.open(facebookShare, "popup", "width=600,height=600")
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          viewBox="0 0 19 19"
          role="img"
        >
          <path d="M8.08865986,17 L8.08865986,10.2073504 L5.7890625,10.2073504 L5.7890625,7.42194226 L8.08865986,7.42194226 L8.08865986,5.08269399 C8.08865986,3.38142605 9.46779813,2.00228778 11.1690661,2.00228778 L13.5731201,2.00228778 L13.5731201,4.50700008 L11.8528988,4.50700008 C11.3123209,4.50700008 10.874068,4.94525303 10.874068,5.48583089 L10.874068,7.42198102 L13.5299033,7.42198102 L13.1628515,10.2073892 L10.874068,10.2073892 L10.874068,17 L8.08865986,17 Z"></path>
        </svg>
      </div>
      <div
        className="cursor-pointer twitter social-icon mr-4"
        onClick={() =>
          window.open(twitterShare, "popup", "width=600,height=600")
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          viewBox="0 0 19 19"
          role="img"
        >
          <path d="M18,4.65548179 C17.3664558,4.9413444 16.6940105,5.12876845 16.0053333,5.21143556 C16.7308774,4.76869949 17.2742158,4.07523994 17.5353333,3.25870539 C16.8519351,3.66952531 16.1046338,3.95967186 15.3253333,4.116758 C14.3449436,3.05903229 12.8270486,2.71461351 11.4952673,3.24769481 C10.1634861,3.78077611 9.28740204,5.08344943 9.28466667,6.53469742 C9.28603297,6.80525838 9.31643401,7.07486596 9.37533333,7.33876278 C6.57168283,7.1960128 3.95976248,5.85317869 2.19,3.64465676 C1.87608497,4.18262214 1.71160854,4.79663908 1.714,5.42164122 C1.61438697,6.56033644 2.09783469,7.6712643 2.99466667,8.36452045 C2.36720064,8.27274888 1.74900117,8.12475716 1.14733333,7.9222845 L1.14733333,7.96708243 C1.26738074,9.69877048 2.5327167,11.1265052 4.21866667,11.4326042 C3.96602896,11.5152522 3.7021383,11.5571156 3.43666667,11.55666 C3.23854288,11.557327 3.0409356,11.5361435 2.84733333,11.4934834 C3.31534048,12.9376046 4.63446966,13.9228162 6.134,13.9481801 C4.90488101,14.9328579 3.38271245,15.4661427 1.816,15.4609716 C1.5432586,15.4614617 1.27074516,15.4449665 1,15.411579 C4.05446938,17.394368 7.93290025,17.5303291 11.1152384,15.7661758 C14.2975765,14.0020226 16.2768505,10.6187983 16.2773333,6.94247342 C16.2773333,6.789701 16.266,6.63692858 16.266,6.4830075 C16.9469737,5.98359293 17.5342367,5.3646551 18,4.65548179 L18,4.65548179 Z"></path>
        </svg>
      </div>
      <div
        className="cursor-pointer linkedin social-icon mr-4"
        onClick={() =>
          window.open(linkedInShare, "popup", "width=600,height=600")
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          viewBox="0 0 19 19"
          role="img"
        >
          <path d="M17,17 L13.89343,17 L13.89343,12.1275733 C13.89343,10.9651251 13.87218,9.47069458 12.2781416,9.47069458 C10.660379,9.47069458 10.4126568,10.7365137 10.4126568,12.0434478 L10.4126568,17 L7.30623235,17 L7.30623235,6.98060885 L10.2883591,6.98060885 L10.2883591,8.3495072 L10.3296946,8.3495072 C10.7445056,7.56190587 11.7585364,6.7312941 13.2709225,6.7312941 C16.418828,6.7312941 17,8.80643844 17,11.5041407 L17,17 Z M3.80289931,5.61098151 C2.80647978,5.61098151 2,4.80165627 2,3.80498046 C2,2.80903365 2.80647978,2 3.80289931,2 C4.79669898,2 5.60434314,2.80903365 5.60434314,3.80498046 C5.60434314,4.80165627 4.79669898,5.61098151 3.80289931,5.61098151 Z M2.24786773,17 L2.24786773,6.98060885 L5.35662096,6.98060885 L5.35662096,17 L2.24786773,17 Z"></path>
        </svg>
      </div>
    </div>
  );
};

const colSize = (num) => {
  try {
    if (num <= 4) {
      return 12 / num;
    } else {
      return 4;
    }
  } catch {
    return 6;
  }
};

const PrismicCaseStudy: React.FC<{ body: Array<any> }> = ({ body }) => {
  return (
    <>
      {body.map((item, index) => {
        if (item.slice_type === "basic_content") {
          return (
            <div className="portion" key={index}>
              {RichTextRender(item.primary.content)}
            </div>
          );
        } else if (item.slice_type === "block_quote") {
          return (
            <blockquote className="portion" key={index}>
              {RichTextRender(item.primary.content)}
            </blockquote>
          );
        } else if (item.slice_type === "raw_html") {
          return (
            <React.Fragment key={index}>
              {item.primary.html_content && (
                <div
                  className="table_section"
                  dangerouslySetInnerHTML={{
                    __html: item.primary.html_content.text,
                  }}
                />
              )}
            </React.Fragment>
          );
        } else if (item.slice_type === "embedded_object") {
          return (
            <React.Fragment key={index}>
              {item.primary.javascript_snippet && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.primary.javascript_snippet,
                  }}
                />
              )}
              {item.primary.embed_url && (
                <iframe width="100%" src={item.primary.embed_url} />
              )}
            </React.Fragment>
          );
        } else if (item.slice_type === "hubspot_form") {
          const { form_id, portal_id } = item.primary;
          return (
            <HubspotForm key={index} formId={form_id} portalId={portal_id} />
          );
        } else if (item.slice_type === "multi_image") {
          const size = colSize(item.items.length);
          return (
            <div className="portion row multi-image" key={index}>
              {item.items.map((item, index: number) => {
                const image = (
                  <div
                    className="inner"
                    style={{ backgroundImage: `url('${item.image.url}')` }}
                  />
                );
                const converted_image_link = convert_image_link(
                  item.image_link
                );
                const inner =
                  _.isObject(item.image_link) &&
                  ((item.image_link.hasOwnProperty("url") &&
                    item.image_link.url !== "") ||
                    (item.image_link.hasOwnProperty("document") &&
                      item.image_link.document)) ? (
                    <Linked link_to={converted_image_link}>{image}</Linked>
                  ) : (
                    image
                  );
                return (
                  <div key={index} className={`col-${size} img`}>
                    {inner}
                  </div>
                );
              })}
            </div>
          );
        } else if (item.slice_type === "anchor_point") {
          const {
            anchor_id,
            anchor_link,
            anchor_link_text,
            anchor_link_target,
          } = item.primary;
          return (
            <>
              {anchor_id ? (
                <div id={anchor_id}></div>
              ) : anchor_link ? (
                <blockquote className="portion anchor-link">
                  <a href={anchor_link} target={anchor_link_target}>
                    {anchor_link_text ? anchor_link_text : anchor_link}
                  </a>
                </blockquote>
              ) : null}
            </>
          );
        } else {
          console.error("Unhandled component in prismic blog", item.type);
          return null;
        }
      })}
    </>
  );
};

const massagePreviewData = (previewData) => ({
  ...previewData,
  body: previewData.body1,
  summary: previewData.summary,
  slug: previewData.uid,
  title: previewData.title,
  meta_title: previewData.meta_title,
  posted_on: moment(previewData.post_date, "YYYY-MM-DD").format("MMM DD, YYYY"),
  author_name: previewData.author.document.data.name,
  author_image: previewData.author.document.data.image.url,
  read_time: previewData.minutes_read_time,
  images: [
    {
      path: previewData.main_image.url,
    },
  ],
});

const BlogPostPage: React.FC<{ pageContext: any; location: any }> = ({
  pageContext,
  location,
}) => {
  const data = usePreviewData(
    pageContext.data,
    "prismicBlogPost",
    true,
    massagePreviewData
  );
  const { legacy, suggestedPosts } = pageContext;

  let mainImage =
    data && data.images && data.images[0] && data.images[0].path
      ? { url: data.images[0].path }
      : undefined;
  mainImage =
    mainImage ||
    (data && data.images && data.images[0] && data.images[0].url
      ? { url: data.images[0].url }
      : undefined);
  const excerpt = data?.summary || undefined;
  const title = data?.meta_title ? data?.meta_title : data?.title;

  return (
    <AmazeePage location={location}>
      <div id="blog-post" className="case-study">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/js/all.min.js"></script>
          {data.canonical_url && (
            <link rel="canonical" href={data.canonical_url} />
          )}
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />

        <AmazeeIoMetadata
          title={data.title}
          page_meta_thumbnail={mainImage}
          page_meta_description={excerpt}
          location={location}
        />

        <div className="container pt-12" style={{ paddingBottom: 17 }}>
          <div className="row d-flex justify-center">
            <div className="col-12">
              <div className="text-left w-full back-link">
                <Linked
                  link_to={{
                    document: {
                      data: {
                        url: "/about-us/customer-stories",
                        is_external: false,
                      },
                    },
                  }}
                >
                  <i className="fa fa-arrow-left mr-2" />
                  Customer Stories
                </Linked>
              </div>
            </div>
            <div className="col-12 col-md-8 col-xl-7">
              <div className="row">
                <div className="col-12 d-none d-flex">
                  <div className="blog__post">
                    <CaseStudyHeaderPost post={data} />
                    {legacy && (
                      <div
                        className="content content--legacy"
                        dangerouslySetInnerHTML={{ __html: pageContext.html }}
                      />
                    )}
                    {!legacy && (
                      <div className="content content--prismic">
                        <PrismicCaseStudy body={data.body1 || data.body} />
                      </div>
                    )}

                    {/* // TODO: Handle prismic */}
                    <hr className="mt-16" />
                    <div className=" mt-8 mb-24">
                      <SocialMediaLinks post={data} location={location} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10 col-xl-8">
              <div className="blog__suggested-posts">
                <div className="d-flex justify-between">
                  <div
                    className="text"
                    style={{ fontWeight: 600, fontSize: 18 }}
                  >
                    Related Content
                  </div>
                </div>
                <div className="suggested-posts__wrapper">
                  {suggestedPosts.map((p: any, index: number) => {
                    const url = `/case-study/${p.slug}`;
                    return (
                      <a className="suggested-post" key={index} href={url}>
                        <div
                          className="suggested-post__image"
                          style={{
                            backgroundImage: `url('${getBlogPostImage(p)}')`,
                          }}
                        />
                        <div className="suggested-post__type">
                          {p.data.company} Case Study
                        </div>
                        <div className="suggested-post__title">
                          {p.data.title}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ResourceInteruptionStatic topMargin={false} />
      </div>
    </AmazeePage>
  );
};

export default BlogPostPage;
